import owasp from "owasp-password-strength-test";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Stack } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { SuitedValidatedTextInput } from "suited/components/shared/inputs/SuitedValidatedTextInput";

import { debounce, timeouts } from "../../constants/interaction-constants";
import { typography } from "../../constants/style-constants";
import AuthenticationTemplate from "../AuthenticationTemplate/AuthenticationTemplate";
import SuitedLink from "../shared/buttons/SuitedLink";
import ButtonRowCentered from "../shared/layout/ButtonRowCentered";
import { Error, ErrorSection, PasswordResetFormWrapper } from "./UserPasswordReset.style";
import { InstructionsCopy } from "./UserRequestPasswordReset.style";
import { clearErrors, getPasswordResetById, resetUserPassword, stateKey } from "./reducers";

interface Props {
  resetUserPassword({ password, resetId }): void;
  getPasswordResetById(id): void;
  clearErrors(): void;
  location: any;
  role: string;
  resetStatus: string;
  errorDetails: string[];
  errors: string[];
  passwordResetSuccess: boolean;
}

interface State {
  passwordResetId: string | null;
  validPassword: string;
  error: boolean;
  navigateToLogin: boolean;
  password: string;
  passwordStrengthResults: any;
  passwordErrorList: string[];
  touched: boolean;
}

const initialState = {
  passwordResetId: "",
  validPassword: "",
  error: false,
  navigateToLogin: false,
  password: "",
  passwordStrengthResults: {},
  passwordErrorList: [],
  touched: false
};

class UserPasswordReset extends React.Component<Props, State> {
  state = {
    ...initialState,
    passwordResetId: new URLSearchParams(this.props.location.search).get("passwordResetId")
  };

  componentDidMount() {
    if (this.state.passwordResetId) {
      this.props.getPasswordResetById(this.state.passwordResetId);
    } else {
      console.error("Could not find password reset id in url; cannot continue");
    }
  }

  submit = (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (this.state.validPassword !== "") {
      this.setState({
        error: false
      });
      this.props.resetUserPassword({
        password: this.state.validPassword,
        resetId: this.state.passwordResetId
      });
    } else {
      this.setState({
        error: true
      });
      this.props.clearErrors();
    }
  };

  handleChangePassword = (value: string) => {
    const password = value;
    const passwordResults = owasp.test(password);
    let passwordErrorList: string[] = [];
    if (passwordResults.errors) {
      passwordErrorList = [...passwordErrorList, ...passwordResults.errors];
    }
    this.setState({
      password,
      passwordStrengthResults: passwordResults,
      passwordErrorList,
      validPassword: passwordResults.strong ? password : ""
    });
  };

  handlePasswordFocus = () => {
    this.setState({ touched: true });
  };

  handleClickSignIn = () => {
    this.setState({ navigateToLogin: true });
  };

  render() {
    if (this.state.navigateToLogin) {
      return <Redirect push to={"/login"} />;
    }
    return (
      <AuthenticationTemplate
        headline="Reset Password"
        ctaText="Sign In"
        ctaOnClick={this.handleClickSignIn}
        children={
          <PasswordResetFormWrapper onSubmit={(e) => this.submit(e)}>
            {this.props.passwordResetSuccess === true ? (
              <Stack>
                <InstructionsCopy className="end">Your password has been reset. </InstructionsCopy>
                <ButtonRowCentered>
                  <SuitedLink to={"/"} tooltip="To Login Page">
                    Click to proceed to the login page.
                  </SuitedLink>
                </ButtonRowCentered>
              </Stack>
            ) : (
              <div>
                {this.props.resetStatus === "ACTIVE" ? (
                  <React.Fragment>
                    <SuitedValidatedTextInput
                      name="password"
                      type="password"
                      onInputChange={this.handleChangePassword}
                      value={this.state.password}
                      label="New Password"
                      autoComplete="new-password"
                      required={true}
                      invalid={
                        this.state.touched &&
                        !(this.state.passwordStrengthResults as Record<string, any>).strong &&
                        this.state.passwordErrorList.length > 0
                      }
                      error={
                        (this.state.passwordErrorList && this.state.passwordErrorList[0]) ||
                        "This field is required."
                      }
                      debounceTime={debounce.typeAhead}
                      overDark={true}
                      onInputFocus={this.handlePasswordFocus}
                    />

                    {this.props.errors && this.props.errors.length > 0 ? (
                      <ErrorSection>
                        <div
                          style={{
                            marginBottom: "10px"
                          }}
                        >
                          {this.props.errors.map((e, i) => {
                            return <Error key={i}>{e}</Error>;
                          })}
                        </div>
                        {this.props.errorDetails && this.props.errorDetails.length > 0 ? (
                          <div
                            style={{
                              color: `${typography.color.light.disabled}`,
                              fontSize: "1.5em",
                              fontWeight: "bold"
                            }}
                          >
                            Suggestions:
                          </div>
                        ) : null}
                        <div>
                          {this.props.errorDetails.map((e, i) => {
                            return <Error key={i}>{e}</Error>;
                          })}
                        </div>
                      </ErrorSection>
                    ) : null}
                    <ButtonRowCentered>
                      <SuitedButton
                        purpose="primary"
                        overDark={true}
                        type="submit"
                        onClick={(e) => this.submit(e)}
                        delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                        disabled={!this.state.validPassword}
                        title="Submit"
                      >
                        Submit
                      </SuitedButton>
                    </ButtonRowCentered>
                  </React.Fragment>
                ) : (
                  <Stack>
                    <InstructionsCopy className="end">
                      Please enter a valid email address.
                    </InstructionsCopy>
                    <ButtonRowCentered>
                      <SuitedLink
                        to="/user-request-password-reset"
                        tooltip="Send a new password reset link"
                      >
                        Would you like another one?
                      </SuitedLink>
                    </ButtonRowCentered>
                  </Stack>
                )}
              </div>
            )}
          </PasswordResetFormWrapper>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  role: state[stateKey].role,
  resetStatus: state[stateKey].resetStatus,
  passwordResetSuccess: state[stateKey].passwordResetSuccess,
  errorDetails: state[stateKey].errorDetails,
  errors: state[stateKey].errors
});

const mapDispatchToProps = (dispatch) => ({
  resetUserPassword: ({ password, resetId }) => {
    dispatch(resetUserPassword({ password, resetId }));
  },
  getPasswordResetById: (id: string) => {
    dispatch(getPasswordResetById(id));
  },
  clearErrors: () => {
    dispatch(clearErrors());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPasswordReset);
