// import other reducers here in order to combine into a single top-level reducer
import alerts, { stateKey as alertsStateKey } from "suited/components/Alerts/reducers";
import { ECA_STATE_KEY, ecaReducer } from "suited/components/CandidateHome/eca/eca.slice";
import {
  LATERAL_STATE_KEY,
  lateralReducer
} from "suited/components/CandidateHome/lateral/lateral.slice";
import {
  FEATURE_FLAG_STATE_KEY,
  featureFlagReducer
} from "suited/components/FeatureFlags/FeatureFlags.slice";
import { authReducer, stateKey as authStateKey } from "suited/components/Login";
import passwordResetReducer, {
  stateKey as passwordResetStateKey
} from "suited/components/PasswordReset/reducers";
import registrationReducer, {
  stateKey as registrationStateKey
} from "suited/components/UserRegistration/reducers";

import {
  USER_SETTINGS_STATE_KEY,
  userSettingsReducer
} from "./components/CandidateHome/UserSettings/userSettings.slice";
import { configKey, configReducer } from "./shared/config/config.slice";

const rootReducer = {
  [alertsStateKey]: alerts,
  [authStateKey]: authReducer,
  [registrationStateKey]: registrationReducer,
  [passwordResetStateKey]: passwordResetReducer,
  [FEATURE_FLAG_STATE_KEY]: featureFlagReducer,
  [ECA_STATE_KEY]: ecaReducer,
  [USER_SETTINGS_STATE_KEY]: userSettingsReducer,
  [LATERAL_STATE_KEY]: lateralReducer,
  [configKey]: configReducer
};

export default rootReducer;
