import _get from "lodash/get";
import React, { useEffect, useState } from "react";
import { pushRotate as BurgerMenu } from "react-burger-menu";
import { match } from "react-router-dom";

import Header from "suited/components/shared/user/Header";
import { burgerMenuStyles } from "suited/constants/style-burger-menu";
import { useInitialMountEffect, usePrevious } from "suited/util/hooks";
import { useAppSelector } from "suited/util/hooks/reduxHooks";

import { AssessmentContextProvider } from "../CandidateHome/Assessment/Context/AssessmentContext";
import { getIsFeatureFlagEnabled } from "../FeatureFlags/FeatureFlags.slice";
import HomeRoutes from "../routes/HomeRoutes";
import SuitedNavigationHeader from "../shared/layout/SuitedNavigationHeader";
import { LaptopAndAbove, PhoneAndTablet } from "../shared/responsive/Responsive";
import {
  Container,
  HeaderContainer,
  HomeContainer,
  HomeMainContent,
  HomeTabContainer
} from "./CandidateHome.style";
import NavigationSidebar from "./Navigation/NavigationSidebar";
import { useProfile } from "./ProfileCompleteProvider";
import { AccountTypeSettingsModal } from "./accountTypeSettings/AccountTypeSettingsModal/AccountTypeSettingsModal";

interface Props {
  // @TODO - upgrade react-router and use a `useRouteMatch` hook instead of prop-drilling
  match: match;
  // @TODO - clean up this whole `data` prop-drilling situation
  // maybe pull each attribute from the Apollo Cache rather than pass the whole query result as a prop?
  // The children this component drills `data` to are able to obtain the attribute with a hook at the leaf, so we can remove the drilling
  data: any;
}

const CandidateHome = (props: Props) => {
  const { match, data } = props;
  const [sidePanelOpen, setSidePanelOpen] = useState<boolean>(false);
  const lateralEnabled = useAppSelector(getIsFeatureFlagEnabled("LATERAL"));

  const { profileState, profileDidLoad, profileWasUpdated } = useProfile();

  useInitialMountEffect(profileDidLoad);

  // This is the only place that we are dispatching the `profileWasUpdated`
  // action because after each section is completed by the user this
  // component is re-rendered due to the link between apollo's cache
  // and the refetching of the GetUser query. We need to call
  // `profileWasUpdated` here because the useQuery hook will be
  // updated whenever one of the profile sections makes a change to
  // the `User`'s profile data.
  const prevData = usePrevious(data);
  useEffect(() => {
    if (prevData !== data) {
      profileWasUpdated();
    }
  }, [data, prevData, profileWasUpdated]);

  const toggleSidePanel = () => {
    setSidePanelOpen(!sidePanelOpen);
  };

  const handleHamburgerMenuClick = () => {
    toggleSidePanel();
    // unfocus so tabbing will begin in the navigation menu
    const activeElement = document.activeElement as HTMLElement;
    activeElement && activeElement.blur();
  };

  const handleClickFeedbackLink = () => {
    const widget = (window as any).FrontChat;
    if (widget) widget("show");
  };

  const isSuitedAssessmentCompleted =
    _get(data, "GetUserSurveyAnswers.status", {}) === "complete" ? true : false;

  const isProfileComplete = profileState.complete;

  return (
    <AssessmentContextProvider>
      <HomeContainer>
        <HomeMainContent>
          <HomeTabContainer sidePanelOpen={sidePanelOpen}>
            <Container id="outer-container">
              <PhoneAndTablet>
                {(isPhoneOrTablet) =>
                  isPhoneOrTablet ? (
                    <React.Fragment>
                      <BurgerMenu
                        isOpen={sidePanelOpen}
                        onStateChange={(state) => setSidePanelOpen(state.isOpen)}
                        styles={burgerMenuStyles}
                        width="17rem"
                        pageWrapId="page-wrap"
                        outerContainerId="outer-container"
                        customBurgerIcon={false}
                        customCrossIcon={false}
                      >
                        <NavigationSidebar
                          user={_get(data, "GetUser")}
                          isSuitedAssessmentCompleted={isSuitedAssessmentCompleted}
                          isProfileComplete={isProfileComplete}
                          onClickFeedback={handleClickFeedbackLink}
                          onNavigate={toggleSidePanel}
                          match={match}
                        />
                      </BurgerMenu>
                    </React.Fragment>
                  ) : (
                    <NavigationSidebar
                      user={_get(data, "GetUser")}
                      isSuitedAssessmentCompleted={isSuitedAssessmentCompleted}
                      isProfileComplete={isProfileComplete}
                      onClickFeedback={handleClickFeedbackLink}
                      onNavigate={toggleSidePanel}
                      match={match}
                    />
                  )
                }
              </PhoneAndTablet>

              <HeaderContainer id="page-wrap">
                <SuitedNavigationHeader onMenuClick={handleHamburgerMenuClick} />
                <LaptopAndAbove>
                  <Header />
                </LaptopAndAbove>

                <HomeRoutes match={match} data={data} />
              </HeaderContainer>
            </Container>
          </HomeTabContainer>
        </HomeMainContent>
      </HomeContainer>
      {lateralEnabled ? <AccountTypeSettingsModal /> : null}
    </AssessmentContextProvider>
  );
};

export default CandidateHome;
