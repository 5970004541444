import { HttpResponse, graphql } from "msw";

import { AccountTypes } from "suited/constants/user.constants";

import { INDUSTRY_CONTEXTS } from "../CandidateHome/Education/education.constants";

export const appBootstrapMockHandlers = [
  // Empty stub in order to run unit tests with msw for components like the
  // nav sidebar
  graphql.query("GetAssessments", () => {
    return HttpResponse.json({
      data: { GetAssessments: [] }
    });
  }),

  graphql.query("GetUserBootstrap", () => {
    return HttpResponse.json({
      data: {
        GetUserSurveyAnswers: null,
        GetUser: {
          id: "fd334c1f-f8d8-4347-85e8-cb410744c94c",
          firstName: "Test",
          lastName: "User",
          email: "testuser+test-school@wellsuited.com",
          accountType: AccountTypes.CAMPUS,
          userProfileCompletion: {
            completedSchools: false,
            completedPersonalInformation: false,
            completedResume: false,
            completedWorkPreferences: false
          },
          settings: {
            visibility: "public",
            __typename: "UserSettings"
          },
          industryContext: {
            id: INDUSTRY_CONTEXTS.FINANCE,
            name: INDUSTRY_CONTEXTS.FINANCE,
            __typename: "IndustryContext"
          },
          accommodations: {
            assessmentTimerMultiplier: 1.0
          },
          __typename: "User"
        },
        GetAssessments: []
      }
    });
  }),

  graphql.query("GetUser", () => {
    return HttpResponse.json({
      data: {
        GetUser: {
          id: "fd334c1f-f8d8-4347-85e8-cb410744c94c",
          firstName: "Test",
          lastName: "User",
          email: "testuser+test-school@wellsuited.com",
          accountType: AccountTypes.CAMPUS,
          userProfileCompletion: {
            completedSchools: false,
            completedPersonalInformation: false,
            completedResume: false,
            completedWorkPreferences: false
          },
          settings: {
            visibility: "public",
            __typename: "UserSettings"
          },
          industryContext: {
            id: INDUSTRY_CONTEXTS.FINANCE,
            name: INDUSTRY_CONTEXTS.FINANCE,
            __typename: "IndustryContext"
          },
          accommodations: {
            assessmentTimerMultiplier: 1.0
          },
          __typename: "User"
        }
      }
    });
  }),

  graphql.query("GetCandidateInvitations", () => {
    return HttpResponse.json({
      data: {
        GetCandidateInvitations: []
      }
    });
  })
];

export const appBootstrapMockHandlerExceptions = {
  PROFILE_PARTIALLY_COMPLETED: graphql.query("GetUserBootstrap", () => {
    return HttpResponse.json({
      data: {
        GetUserSurveyAnswers: null,
        GetUser: {
          id: "fd334c1f-f8d8-4347-85e8-cb410744c94c",
          firstName: "Test",
          lastName: "User",
          email: "testuser+test-school@wellsuited.com",
          accountType: AccountTypes.CAMPUS,
          userProfileCompletion: {
            completedSchools: true,
            completedPersonalInformation: true,
            completedResume: false,
            completedWorkPreferences: true,
            __typename: "UserProfileCompletionData"
          },
          settings: {
            visibility: "public",
            __typename: "UserSettings"
          },
          industryContext: {
            id: INDUSTRY_CONTEXTS.FINANCE,
            name: INDUSTRY_CONTEXTS.FINANCE,
            __typename: "IndustryContext"
          },
          accommodations: {
            assessmentTimerMultiplier: 1.0
          },
          __typename: "User"
        },
        GetAssessments: []
      }
    });
  }),

  // @ts-ignore
  PROFILE_FULLY_COMPLETED: graphql.query("GetUserBootstrap", () => {
    return HttpResponse.json({
      data: {
        GetUserSurveyAnswers: {
          status: "complete"
        },
        GetUser: {
          id: "fd334c1f-f8d8-4347-85e8-cb410744c94c",
          firstName: "Test",
          lastName: "User",
          email: "testuser+test-school@wellsuited.com",
          accountType: AccountTypes.CAMPUS,
          userProfileCompletion: {
            completedSchools: true,
            completedPersonalInformation: true,
            completedResume: true,
            completedWorkPreferences: true,
            __typename: "UserProfileCompletionData"
          },
          settings: {
            visibility: "public",
            __typename: "UserSettings"
          },
          industryContext: {
            id: INDUSTRY_CONTEXTS.FINANCE,
            name: INDUSTRY_CONTEXTS.FINANCE,
            __typename: "IndustryContext"
          },
          accommodations: {
            assessmentTimerMultiplier: 1.0
          },
          __typename: "User"
        },
        GetAssessments: []
      }
    });
  })
};
