import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../configureStore";

export const configKey = "config";

const configSlice = createSlice({
  name: configKey,
  initialState: {
    isStorybook: false
  },
  reducers: {
    setIsStorybook: (state, action) => {
      state.isStorybook = action.payload;
    }
  }
});

// Selectors
export const getIsStorybook = (state: RootState) => state.config.isStorybook;

// Reducer
export const configReducer = configSlice.reducer;
